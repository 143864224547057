.MuiTypography-root {
    font-family: 'dm-sans-regular' !important;
    font-size: 30px !important;
    color: #fff;
}

.MuiCardContent-root.css-46bh2p-MuiCardContent-root {
    padding: 0;
    border: 0;
    padding-left: 25px;
}

.simple-card {
    margin-top: 0;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -ms-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;

    img {
        height: 450px;
    }

    @media only screen and (max-width: 700px) {
        margin-top: 20px;
        
        img {
            height: 230px;
        }
    }


}

.simple-card:hover {
    @media only screen and (min-width: 1024px) {
        margin-top: -6px;
        -webkit-transition: 300ms;
        -moz-transition: 300ms;
        -ms-transition: 300ms;
        -o-transition: 300ms;
        transition: 300ms;
    }
}


