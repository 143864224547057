.button {
  button {
    border: none;
    font-family: 'dm-sans-medium';
    font-size: 1vw;
    cursor: pointer;
  }
  .book-btn {
    width: 8vw;
    height: 2.5vw;
    background: linear-gradient(270deg, #39FACD -2.01%, #7EA1EA 101.46%);
    border-radius: 23.5px;

    img {
      height: 0.8vw;
      width: 0.8vw;
      padding-left: 8px;
    }
  }
}