.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  background: linear-gradient(90deg, #7EA1EA -19.88%, #39FACD 110.58%);
  font-family: 'dm-sans-regular';

  .footer-content {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 100px;

    @media only screen and (max-width: 950px) {
      margin-top: 50px;
      flex-direction: column;
    }

    .footer-bar {
      display: flex;
      flex-direction: column;
      margin-left: 25px;

      .footer-links {
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 950px) {
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin: 10px 0;
          

          a {
            margin-right: 25px;
          }

          // a:not(:first-child) {
          //   margin: 10px 25px;
          // }

          p {
            margin: 10px 0;
            margin-right: 25px;
            max-width: 250px;
          }
        }
      }

      .logo {
        width: 120px;
        height: 30px;

        @media only screen and (max-width: 950px) {
          margin-bottom: 50px;
        }
      }

      a {
        color: #fff;
        text-decoration: none;
        margin: 10px 0;
        font-weight: lighter;
        font-family: $breez-font-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.03em;
      }

      h3 {
        font-family: $breez-font-regular;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.03em;
      }

      .follow-section {
        img {
          margin: 0 10px;
          cursor: pointer;
        }
      }
    }
  }

  hr {
    width: 80%;
    margin: 60px auto;
    border: 1px solid #fff;

    @media only screen and (max-width: 950px) {
      display: none;
    }
  }

  .copyright {
    text-align: center;
    margin-bottom: 60px;
  }
}