  @font-face {
    font-family: 'dm-sans-bold';
    src: url("../public/fonts/DMSans-Bold.ttf") format('truetype');
  }
  
  @font-face {
    font-family: 'dm-sans-bold-italic';
    src: url("../public/fonts/DMSans-BoldItalic.ttf") format('truetype');
  }
  
  @font-face {
    font-family: 'dm-sans-italic';
    src: url("../public/fonts/DMSans-Italic.ttf") format('truetype');
  }
  
  @font-face {
    font-family: 'dm-sans-medium';
    src: url("../public/fonts/DMSans-Medium.ttf") format('truetype');
  }
  
  @font-face {
    font-family: 'dm-sans-medium-italic';
    src: url("../public/fonts/DMSans-MediumItalic.ttf") format('truetype');
  }
  
  @font-face {
    font-family: 'dm-sans-regular';
    src: url("../public/fonts/DMSans-Regular.ttf") format('truetype');
  }
  

$breez-font-regular: 'dm-sans-regular';
$breez-font-bold: 'dm-sans-bold';
$breez-font-medium: 'dm-sans-medium';
$breez-font-light: 'tthoves-light';
$breez-font-demibold: 'dm-sans-demibold';



@import "./components/Home/HomePage/HomePage.scss";

@import "./components/Shared/Layout/Layout.scss";
@import "./components/Shared/Footer/Footer.scss";
@import "./components/Shared/Menu/Menu.scss";
@import "./components/Shared/Menu/MenuItem/MenuItem.scss";
@import "./components/Shared/Button/Button.scss";
@import "./components/Shared/Page/Page.scss";
@import "./components/Shared/Input/Input.scss";
@import "./components/Shared/Cards/SimpleCard.scss";
@import "./components/Shared/Cards/DetailedCard.scss";