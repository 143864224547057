.menu-item {
    border: 0;
    background: none;
    font-family: "dm-sans-regular";
    color: white;
    font-size: 18px;
    padding: 15px 50px;

    div {
        cursor: pointer;
    }   
}