.detailed-card {
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -ms-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;

  width: 400px;

  margin-top: 20px;

  @media only screen and (max-width: 380px) {
    width: 250px;
  }

  .title {
    margin: 20px 0;
  
    font-family: 'dm-sans-bold';
    font-size: 20px;
  }
  
  .location {
    margin: 20px 0;
    display: flex;
  
    font-family: 'dm-sans-regular';
    color: #949AB2;
    
    img {
      height: 20px;
      width: 20px;
      opacity: 0.2;
    }
  }
  
  .main-info {
    margin: 20px 0;
    font-family: 'dm-sans-regular';
    color: #949AB2;
  }
  
  .price {
    margin: 20px 0;
    font-size: 25px;
    font-family: 'dm-sans-bold';
  
    span {
      font-size: 20px;
      font-family: 'dm-sans-regular';
      color: #949AB2;
    }
  }
}

.detailed-card:hover {
  @media only screen and (min-width: 1024px) {
    margin-top: -1px;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -ms-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
  }
}