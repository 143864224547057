body {
    margin: 0;
}

.home-wrapper {
    .section-title {
        font-family: $breez-font-bold;
        margin-top: 100px;

        @media only screen and (max-width: 768px) {
            text-align: center;
        }
    }

    .hero-wrapper {
        position: relative;
        color: #fff;
        font-family: $breez-font-regular;
        font-style: normal;
        text-align: center;
        letter-spacing: -0.04em;

        .hero-overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #000;
            z-index: 1;
            opacity: 0.5;
        }

        img {
            width: 100%;

            @media only screen and (max-width: 700px) {
                height: 615px;
                object-fit: cover;
            }
        }

        .hero-text {
            position: absolute;
            top: 30%;
            width: 100%;
            text-align: center;
            z-index: 2;

            h2 {
                width: 580px;
                font-weight: 700;
                font-size: 48px;
                line-height: 112.2%;
                margin: auto;
                margin-bottom: 26px;

                @media only screen and (max-width: 1200px) {
                    width: 450px;
                    font-size: 40px;
                }

                @media only screen and (max-width: 700px) {
                    width: 320px;
                    font-size: 30px;
                }

                @media only screen and (max-width: 500px) {
                    width: 250px;
                }
            }
        
            p {
                width: 370px;
                font-weight: 500;
                font-size: 20px;
                line-height: 130.7%;
                margin: auto;

                @media only screen and (max-width: 1200px) {
                    width: 300px;
                    font-size: 16px;
                }

                @media only screen and (max-width: 700px) {
                    width: 300px;
                }

                @media only screen and (max-width: 500px) {
                    font-size: 20px;
                    width: 250px;
                }
            }
        }
    }

    .multi-search-wrapper {
        width: 80%;
        margin: auto;
        
        .multi-search-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
    
            .multi-search-line {
                width: 100%;
                background-color: #E7E7E7;
                height: 2px;
                margin-top: 20px;
            }
    
            .multi-search-tabs {
                width: 100%;
                margin-top: 100px;
    
                display: grid;
                grid-template-columns: auto auto auto;
                column-gap: 50px;
                justify-content: start;
    
                .multi-search-tab {
                    font-family: 'dm-sans-bold';
                    font-size: 15px;
                    color: #C5C5C5;
                    cursor: pointer;
    
                    &.active {
                        color: #0F192E;
                    }
                }
            }
        }

        .multi-search-content {
            margin-top: 60px;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            justify-content: center;
            grid-column-gap: 50px;

            label {
                margin-top: 15px;
            }

            input {
                width: 250px;
            }
        }
    }

    .mask-wrapper {
        margin-top: 100px;

        .mask-image-wrapper {
            position: relative;

            .mask-text-wrapper {
                position: absolute;
                top: 30%;
                z-index: 1;
                color: #fff;
                width: 25%;
                height: 30%;
                left: 5%;

                h2 {
                    font-family: 'dm-sans-bold';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 95.7%;
                    letter-spacing: -0.03em;
                }

                p {
                    font-family: 'dm-sans-regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 121.7%;
                }
                
            }

            .slide-image {
                position: absolute;
                object-fit: cover;
                top: 20%;
                right: 19%;
                height: 62%;
            }

            .mask {
                position: relative;
                width: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
            }
        }

      }

    .main-cards-wrapper {
        background: rgba(126, 161, 234, 0.15);

        .main-cards-container {
            display: grid;
            grid-template-columns: auto auto;
            width: 80%;
            column-gap: 20px;
            margin: auto;
            margin-top: 50px;
            // overflow-x: auto;

            @media only screen and (max-width: 1000px) {
                grid-template-columns: auto;
            }
            
        }
    }

    .recommendations-wrapper {
        width: 80%;
        margin: auto;
        overflow-x: auto;

        .show-more-btn {
            width: 100%;
            margin-top: 50px;
            margin-bottom: 10px;
            text-align: center;

            button {
                width: 165px;
                height: 60px;
                color: #fff;
                cursor: pointer;
                font-family: 'dm-sans-regular';
                background: linear-gradient(270deg, #39FACD -2.01%, #7EA1EA 101.46%);
                border: 0;
                font-size: 16px;
                border-radius: 7px;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }
        }

        .recommendations-container {
            display: grid;
            grid-template-columns: auto auto auto auto;
            justify-items: center;
            margin-top: 50px;
            overflow-x: auto;
        } 
    }

    .help-section {
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 100px;
        height: 330px;
        background-color: #F3F3F3;
        justify-content: space-around;
        align-items: center;
        font-family: $breez-font-regular;
        font-style: normal;
        color: #474747;
        letter-spacing: -0.04em;
        text-align: center;

        @media only screen and (max-width: 700px) {
            padding: 50px 0;
        }

        .help-buttons {
            @media only screen and (max-width: 700px) {
                display: flex;
                flex-direction: column;
            }

            button {
                background-color: #7EA1EA;
                font-family: $breez-font-bold;
                color: #fff;
                border: 0;
                height: 60px;
                width: 170px;
                border-radius: 7px;
                margin: 20px 20px;
    
                @media only screen and (max-width: 700px) {
                    font-size: 18px;
                    width: 200px;
                    height: 80px;
                    margin: 20px 10px;
                }
            }
        }

        h1 {
            font-weight: 700;
            font-size: 34px;
            line-height: 95.7%;
            margin-bottom: 35px;

            @media only screen and (max-width: 700px) {
                font-size: 26px;
            }
        }

        p {
            font-family: $breez-font-regular;
            font-weight: 600;
            font-size: 16px;
            line-height: 155.7%;
            width: 330px;

            @media only screen and (max-width: 700px) {
                font-size: 16px;
            }

            @media only screen and (max-width: 380px) {
                font-size: 16px;
                width: 250px;
            }
        }
    }
}