.label {
  display: flex;
  flex-direction: column;
  font-family: 'dm-sans-bold';
  font-size: 16px;

  input {
    background-color: #EFEFEF;
    font-family: 'dm-sans-regular';
    height: 60px;
    border: 0;
    border-radius: 6px;
    text-align: left;
    padding-left: 40px;
    color: #9B9B9B;
    margin-top: 20px;
  }
}