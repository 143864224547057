.page {
  min-height: calc(100vh - 140px);
  padding: 14px;

  @media only screen and (max-width: 1000px) {
    padding: 0 30px 0 30px;
  }


  @media only screen and (max-width: 834px) {
    min-height: calc(100vh - 270px);
  }

  &.page-mode-flex {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &.center {
    display: flex;
    justify-content: center;
  }

  .wrapper {
    max-width: 1200px;
    width: 100%;
  }
}
